@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  background-image: url("./assets/weway-bg.png");
  background-size: cover;
}
body {
  margin: 0;
  font-family: "Druk Text Wide Cyr", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-wrapper {
  width: 100%;
  position: relative;
  background: #181a26;
  border-radius: 20px;
}
.card-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 3px;
  background: linear-gradient(
    180deg,
    #48d1cc 0%,
    rgba(72, 209, 204, 0) 120.69%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.claim {
  position: relative;
  border-radius: 20px;
  background: rgba(30, 32, 44, 0.8);
  opacity: 0.8;
  backdrop-filter: blur(8px);
}

.claim::before {
  z-index: -1;
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 4px;
  background: linear-gradient(
    180deg,
    #48d1cc 0%,
    rgba(43, 45, 59, 0) 52.84%,
    #48d1cc 100%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.wallet-error {
  width: 100%;
  position: relative;
  border-radius: 20px;
  background: rgba(30, 32, 44, 0.8);
  opacity: 0.8;
  backdrop-filter: blur(8px);
}

.wallet-error::before {
  z-index: -1;
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 4px;
  background: linear-gradient(
    180deg,
    #b26762 0.01%,
    rgba(43, 45, 59, 0) 52.84%,
    #b26762 100%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.card-dot-right {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translate(0, -50%);
  right: -10px;
  width: 22.05px;
  height: 22.05px;
  background: #181a26;
  border: 3px solid #48d1cc;
  box-shadow: 0px 4px 27px rgba(114, 255, 250, 0.5);
  border-radius: 50%;
}

.card-dot-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -10px;
  width: 22.05px;
  height: 22.05px;
  background: #181a26;
  border: 3px solid #48d1cc;
  box-shadow: 0px 4px 27px rgba(114, 255, 250, 0.5);
  border-radius: 50%;
}

.card-wrapper::after {
  content: "- - - - - - - -";
  color: #2d2f3f;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(115%, -13px);
  height: 0px;
}

.card-wrapper:last-of-type::after {
  display: none;
}

.RSPBprogressBar {
  z-index: 2 !important;
}

.vertical-percent-flag {
  display: none;
}

@media (max-width: 785px) {
  .card-dot-right,
  .card-dot-left,
  .card-wrapper::after {
    display: none;
  }

  .claim::before {
    padding: 4px 0;
    border-radius: 0px;
    background: linear-gradient(
      90deg,
      #48d1cc 0%,
      rgba(43, 45, 59, 0) 52.84%,
      #48d1cc 100%
    );
  }

  .wallet-error::before {
    border-radius: 0px;
    padding: 4px 0;
    background: linear-gradient(
      90deg,
      #b26762 0.01%,
      rgba(43, 45, 59, 0) 52.84%,
      #b26762 100%
    );
  }
}

@media (max-width: 485px) {
  .progressbar {
    transform: rotate(90deg);
    margin-top: 100px;
    margin-bottom: 180px;
  }

  .step {
    transform: rotate(-90deg);
  }

  .step-date {
    left: 60px;
    top: 10%;
  }

  .step-text {
    font-size: 1.25rem;
    left: 60px;
    top: 50%;
  }

  .horizontal-percent-flag {
    display: none;
  }

  .vertical-percent-flag {
    transform: rotate(-90deg) translateY(-57px) translateX(-60px);
    display: block;
  }
}

@font-face {
  font-family: "Druk Text Wide Cyr";
  src: local("Druk Text Wide Cyr"),
    url("./assets/fonts/Druk\ Text\ Wide\ Cyr\ Bold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Druk Text Wide Cyr";
  src: local("Druk Text Wide Cyr"),
    url("./assets/fonts/Druk\ Text\ Wide\ Cyr\ Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Druk Text Wide Cyr";
  src: local("Druk Text Wide Cyr"),
    url("./assets/fonts/Druk\ Text\ Wide\ Cyr\ Heavy.otf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
